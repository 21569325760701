import configureDemo from "./demo.actions";
import configureScene from "./scene.actions";
import configureResult from "./result.actions";
import configureAuth from "./auth.actions";
import axios from "axios";

axios.interceptors.request.use(
  (req) => {
    const access_token = localStorage.getItem("access_token");
    req.headers["Authorization"] = `Bearer ${access_token}`;
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (res) => {
    if (res.status === 201) {
      console.log("Posted Successfully");
    }
    return res;
  },
  (err) => {
    return Promise.reject(err);
  }
);

const demo = configureDemo(axios);
const scene = configureScene(axios);
const result = configureResult(axios);
const auth = configureAuth();
export const actions = {
  demo,
  scene,
  result,
  auth,
};
