import React from "react";
import { CloseBtn, ReplayBtn } from "./Buttons";

export const ResultMenu = (props) => {
  const { type, id } = props;
  return (
    <>
      <div
        className="result-menu d-flex justify-content-end mt-3"
        id="result-menu-overlay"
      >
        <ReplayBtn onClick={props.replayClick} />
        <CloseBtn type={type} id={id} />
      </div>
    </>
  );
};

export default ResultMenu;
