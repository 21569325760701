/* eslint-disable import/no-anonymous-default-export */
import {
  GET_ACCESS_TOKEN,
  GET_ACCESS_TOKEN_SUCCESS,
  GET_ACCESS_TOKEN_FAILURE,
} from "./types";
import axios from "axios";
import moment from "moment";

export default () => {
  const exports = {
    getAccessToken,
  };

  function getAccessToken(timeout) {
    const now = moment().unix();
    return async (dispatch) => {
      dispatch({ type: GET_ACCESS_TOKEN, payload: { pending: true } });

      if (timeout < now) {
        try {
          const data = {
            client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
            client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
            audience: process.env.REACT_APP_AUTH_AUD,
            grant_type: "client_credentials",
          };
          const res = await axios.post(
            `${process.env.REACT_APP_AUTH0_DOMAIN}${process.env.REACT_APP_AUTH0_URI}`,
            data
          );
          const body = res.data;
          body.expire_time = now + body.expires_in;

          return dispatch({
            type: GET_ACCESS_TOKEN_SUCCESS,
            payload: body,
          });
        } catch (ex) {
          console.log(ex);
          dispatch({ type: GET_ACCESS_TOKEN_FAILURE, error: ex });
        }
      }
    };
  }

  return exports;
};
