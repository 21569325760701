import {
  GET_ACCESS_TOKEN,
  GET_ACCESS_TOKEN_SUCCESS,
  GET_ACCESS_TOKEN_FAILURE,
} from "../actions/types";

const initialState = {
  access_token: localStorage.getItem("access_token"),
  expires_in: localStorage.getItem("expires_in"),
  token_type: localStorage.getItem("token_type"),
  expire_time: localStorage.getItem("expire_time"),
  pending: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCESS_TOKEN_SUCCESS:
      localStorage.setItem("access_token", action.payload.access_token);
      localStorage.setItem("expires_in", action.payload.expires_in);
      localStorage.setItem("expire_time", action.payload.expire_time);
      localStorage.setItem("token_type", action.payload.token_type);
      return {
        ...state,
        access_token: action.payload.access_token,
        expires_in: action.payload.expires_in,
        token_type: action.payload.token_type,
        expire_time: action.payload.expire_time,
        pending: false,
      };
    case GET_ACCESS_TOKEN:
      return {
        ...state,
        pending: action.payload.pending,
      };
    case GET_ACCESS_TOKEN_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default auth;
