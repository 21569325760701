import { GET_SCENE_SUCCESS } from "../actions/types";

const initialState = {
  scene: {},
};

const scene = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCENE_SUCCESS:
      return {
        ...state,
        scene: action.payload,
      };
    default:
      return state;
  }
};

export default scene;
