import { GET_RESULT_SUCCESS, LIST_RESULTS_SUCCESS } from "../actions/types";

const initialState = {
  result: {},
  results: [],
};

const result = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESULT_SUCCESS:
      return {
        ...state,
        result: action.payload,
      };
    case LIST_RESULTS_SUCCESS:
      return {
        ...state,
        results: [...action.payload],
      };
    default:
      return state;
  }
};

export default result;
