export const GET_DEMO_BY_SLUG = "GET_DEMO_BY_SLUG";
export const GET_DEMO_SUCCESS = "GET_DEMO_SUCCESS";
export const GET_DEMO_FAILURE = "GET_DEMO_FAILURE";
export const GET_SCENE_BY_ID = "GET_SCENE_BY_ID";
export const GET_SCENE_SUCCESS = "GET_SCENE_SUCCESS";
export const GET_SCENE_FAILURE = "GET_SCENE_FAILURE";
export const GET_RESULT_BY_ID = "GET_RESULT_BY_ID";
export const GET_RESULT_SUCCESS = "GET_RESULT_SUCCESS";
export const GET_RESULT_FAILURE = "GET_RESULT_FAILURE";
export const LIST_RESULTS_SUCCESS = "LIST_RESULTS_SUCCESS";
export const GET_ACCESS_TOKEN = "GET_ACCESS_TOKEN";
export const GET_ACCESS_TOKEN_SUCCESS = "GET_ACCESS_TOKEN_SUCCESS";
export const GET_ACCESS_TOKEN_FAILURE = "GET_ACCESS_TOKEN_FAILURE";
