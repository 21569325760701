import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { actions } from "../actions";
import { AnimatePresence, motion } from "framer-motion";
import { Demo } from "../components/Demo";
import { NotFound } from "../components/NotFound";
import { Result } from "../components/Result";
import { SceneContainer } from "../components/SceneContainer";
import "../css/index.css";

export const Wrapper = () => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={{
        initial: {
          opacity: 0,
        },
        in: {
          opacity: 1,
        },
        out: {
          opacity: 0,
        },
      }}
      transition={{
        type: "spring",
        damping: 10,
        stiffness: 50,
      }}
    >
      <Outlet />
    </motion.div>
  );
};

const App = () => {
  const { access_token, expire_time } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.auth.getAccessToken(expire_time));
  }, [dispatch, expire_time]);

  const location = useLocation();

  return (
    <div className="wrapper">
      {access_token && (
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.pathname}>
            <Route element={<Wrapper />}>
              <Route element={<Demo />} path="/demo/:slug" />
              <Route element={<SceneContainer />} path="/scene/:id" />
              <Route element={<Result />} path="/result/:id" />
              <Route element={<NotFound />} path="*" />
            </Route>
          </Routes>
        </AnimatePresence>
      )}
    </div>
  );
};

export default App;
