/* eslint-disable import/no-anonymous-default-export */
import { GET_SCENE_BY_ID, GET_SCENE_SUCCESS, GET_SCENE_FAILURE } from "./types";

export default (axios) => {
  const exports = {
    getById,
  };

  function getById(id) {
    return async (dispatch) => {
      dispatch({ type: GET_SCENE_BY_ID });
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/scene/${id}`
        );
        const body = res.data;
        return dispatch({
          type: GET_SCENE_SUCCESS,
          payload: body,
        });
      } catch (ex) {
        dispatch({ type: GET_SCENE_FAILURE, error: ex });
      }
    };
  }

  return exports;
};
