/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { mat2 } from "gl-matrix";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";
import Image from "react-bootstrap/Image";
import hotspotImg from "../assets/buttons/Hotspot.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

class Hotspot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.hotspot = React.createRef();

    this.resultLinkClick = this.resultLinkClick.bind(this);
    this.toggleOverlay = this.toggleOverlay.bind(this);
  }

  toggleOverlay = (event, value) => {
    this.setState((state, props) => ({ show: value }));
  };

  resultLinkClick = (event, id) => {
    const { navigate, transition, scene_height, scene_width } = this.props;

    transition
      ? navigate(
          `/transition/${id}/${Number(scene_width).toFixed(2)}/${Number(
            scene_height
          ).toFixed(2)}/${this.props.scene.pk}`
        )
      : navigate(`/result/${id}`);
  };

  render() {
    var destViewportPix = [
      (this.props.scene_width * 16.0) / 9.0,
      this.props.scene_height,
    ];
    var vidContSize = [this.props.scene_width, this.props.scene_height];
    // Set the last two values to zero, we dont need them, we only need one quadrant
    var hotSpotPos = mat2.fromValues(
      this.props.hotspot_x_location,
      this.props.hotspot_y_location,
      0,
      1.0
    );

    // Index 0 = width
    // Index 1 = height
    var hotSpotPosPix = [
      ((hotSpotPos[0] + 1.0) / 2.0) * destViewportPix[0] +
        (destViewportPix[0] - vidContSize[0]) * -0.5,
      (1.0 - (hotSpotPos[1] + 1.0) / 2.0) * destViewportPix[1] +
        (destViewportPix[1] - vidContSize[1] * 1.0) * -0.5,
    ];

    const style = {
      left: hotSpotPosPix[0],
      top: hotSpotPosPix[1],
    };

    const placement = this.props.hotspot_x_location > 0 ? "left" : "right";

    const popover = (
      <Popover
        id="popover-basic"
        onMouseEnter={(e) => this.toggleOverlay(e, true)}
        onMouseLeave={(e) => this.toggleOverlay(e, false)}
      >
        <Popover.Header as="h3">{this.props.tooltip.title}</Popover.Header>
        <Popover.Body>
          <div className="popover-body-text">
            {this.props.tooltip.text} <br></br>{" "}
          </div>
          {this.props.children ? (
            this.props.children.map((child, key) => {
              return (
                <a href="#" onClick={(e) => this.resultLinkClick(e, child.id)}>
                  <span className="popover-footer" key={key}>
                    {" "}
                    WATCH VIDEO <FontAwesomeIcon icon={faPlay} /> {child.name}
                  </span>
                </a>
              );
            })
          ) : (
            <span className="popover-footer">
              {" "}
              <a
                href="#"
                onClick={(e) => this.resultLinkClick(e, this.props.nextPage)}
              >
                WATCH VIDEO <FontAwesomeIcon icon={faPlay} />
              </a>
            </span>
          )}
        </Popover.Body>
      </Popover>
    );

    return (
      <>
        <Overlay
          placement={placement}
          target={this.hotspot.current}
          show={this.state.show}
        >
          {popover}
        </Overlay>
        <div
          className="hotspotDiv"
          ref={this.hotspot}
          data-next-page={this.props.nextPage}
          data-x-location={this.props.hotspot_x_location}
          data-y-location={this.props.hotspot_y_location}
          data-popover-title={this.props.tooltip.id}
          data-popover-content={this.props.tooltip.id}
          onClick={this.props.onClick}
          onMouseEnter={(e) => this.toggleOverlay(e, true)}
          onMouseLeave={(e) => this.toggleOverlay(e, false)}
          style={style}
        >
          <Image src={hotspotImg} alt="hotspot" />
        </div>
      </>
    );
  }
}

export default Hotspot;
