import { useParams } from "react-router";
import { useEffect } from "react";
import { actions } from "../actions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../css/scene.css";

export const Demo = () => {
  const { slug } = useParams();
  const { demo } = useSelector((state) => state.demo);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.demo.getBySlug(slug));
  }, [dispatch, slug]);

  useEffect(() => {
    const { loading, landing_scene } = demo;
    if (!loading && landing_scene) navigate(`/scene/${landing_scene.id}`);
  }, [navigate, demo]);

  return null;
};
