/* eslint-disable import/no-anonymous-default-export */
import { GET_DEMO_BY_SLUG, GET_DEMO_SUCCESS, GET_DEMO_FAILURE } from "./types";

export default (axios) => {
  const exports = {
    getBySlug,
  };

  function getBySlug(slug) {
    return async (dispatch) => {
      dispatch({ type: GET_DEMO_BY_SLUG, payload: { loading: true } });
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/demo/getBySlug/${slug}`
        );
        const body = res.data;
        return dispatch({
          type: GET_DEMO_SUCCESS,
          payload: body,
        });
      } catch (ex) {
        dispatch({ type: GET_DEMO_FAILURE, error: ex });
      }
    };
  }

  return exports;
};
