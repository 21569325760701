import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import VideoOverlay from './VideoOverlay';
import SlideOut from './SlideOut';
import ResultDescription from './ResultDescription';
import '../css/result.css';
import { useParams } from 'react-router';
import { actions } from '../actions';

export const Result = (props) => {
  const { id } = useParams();
  const { result } = useSelector((state) => state.result);

  const [videoEnded, setVideoEnded] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.result.getById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (videoEnded) props.videoEnded();
  });

  return (
    <div className="video-wrapper">
      {result.id && (
        <VideoOverlay
          child={props.child ? props.child : null}
          // onEnded={setVideoEnded(true)}
          video={result.video}
          autoPlay={true}
          controls={true}
          result_menu={true}
          replayVideo={() => setVideoEnded(false)}
          type="scene"
          id={result.id}
          scene_id={result.scene.id}
        />
      )}
      {result.descriptions && (
        <SlideOut visible={videoEnded} video={true}>
          <ResultDescription result={result} />
        </SlideOut>
      )}
    </div>
  );
};
