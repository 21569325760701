import React from "react";
// import Logo from "../assets/logos/10x_Logo_slide_out.svg";
import "../css/result.css";

const ResultDescription = (props) => {
  const { result } = props;

  return (
    <div
      className={result.is_spec ? "spec-container" : "description-container"}
    >
      {result.descriptions.map((description, index) => (
        <div key={index}>
          <div
            className={`description ${
              result.is_spec ? "spec-title" : "description-title"
            }`}
          >
            {description.title}
          </div>
          {description.subtitle && (
            <div
              className={`description ${
                result.is_spec ? "spec-subtitle" : "description-subtitle"
              }`}
            >
              {description.subtitle}
            </div>
          )}
          <div
            className={`description ${
              result.is_spec ? "spec-bullets" : "description-bullets"
            }`}
          >
            <ul>
              {description.bullets.map((bullet) => (
                <li key={bullet.pk}>{bullet.text}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
      {/* <div className="d-flex flex-row-reverse logo-results">
        <img src={Logo} style={{ width: "100%", height: "99%" }} alt="stuff" />
      </div> */}
    </div>
  );
};

export default ResultDescription;
