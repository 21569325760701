/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { useParams } from "react-router";
import "../css/scene.css";
import Scene from "./Scene";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../actions";
import { useNavigate } from "react-router-dom";

export const SceneContainer = () => {
  const { id } = useParams();
  const { scene } = useSelector((state) => state.scene);
  const { results } = useSelector((state) => state.result);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.scene.getById(id));
    dispatch(actions.result.listBySceneId(id));
  }, [dispatch, id]);

  return (
    <>
      {scene.background_img && results && (
        <div className="scene-container d-flex justify-content-center transition-enabled">
          <Scene
            backgroundImg={scene.background_img}
            scene={scene}
            navigate={navigate}
          />
        </div>
      )}
    </>
  );
};
