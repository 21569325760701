/* eslint-disable import/no-anonymous-default-export */
import {
  GET_RESULT_BY_ID,
  GET_RESULT_SUCCESS,
  GET_RESULT_FAILURE,
  LIST_RESULTS_SUCCESS,
} from "./types";

export default (axios) => {
  const exports = {
    getById,
    listBySceneId,
  };

  function getById(id) {
    return async (dispatch) => {
      dispatch({ type: GET_RESULT_BY_ID });
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/result/${id}`
        );
        const body = res.data;
        return dispatch({
          type: GET_RESULT_SUCCESS,
          payload: body,
        });
      } catch (ex) {
        dispatch({ type: GET_RESULT_FAILURE, error: ex });
      }
    };
  }

  function listBySceneId(id) {
    return async (dispatch) => {
      dispatch({ type: GET_RESULT_BY_ID });
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/result/listBySceneID/${id}`
        );
        const body = res.data;
        return dispatch({
          type: LIST_RESULTS_SUCCESS,
          payload: body,
        });
      } catch (ex) {
        dispatch({ type: GET_RESULT_FAILURE, error: ex });
      }
    };
  }

  return exports;
};
