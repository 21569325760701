import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FAQ from './FAQ.js';
import '../css/faq.css';

export const FaqList = (props) => {
  const [openQuestions, setopenQuestions] = useState([]);
  const {
    demo: { faqs },
  } = useSelector((state) => state.demo);

  const toggleQuestion = (id) => {
    const index = openQuestions.indexOf(id);
    let openQuestionsCopy;
    openQuestionsCopy = [...openQuestions];
    if (index !== -1) {
      openQuestionsCopy.splice(index, 1);
    } else {
      openQuestionsCopy.push(id);
    }

    setopenQuestions(openQuestionsCopy);
  };

  return (
    <div>
      <div id="faq-content">
        {faqs &&
          faqs.map((faq) => (
            <FAQ
              faq={faq}
              open={openQuestions.indexOf(faq.id) !== -1}
              toggleOpen={(id) => toggleQuestion(id)}
              key={faq.id}
            />
          ))}
      </div>
      <div id="question-btn" className="d-flex justify-content-center p-3 mt-4">
        <button
          type="button"
          className="btn btn-primary custom-btn"
          onClick={() => props.contactUsClick()}
        >
          Contact Us
        </button>
      </div>
    </div>
  );
};
