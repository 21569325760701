import {
  GET_DEMO_BY_SLUG,
  GET_DEMO_FAILURE,
  GET_DEMO_SUCCESS,
} from "../actions/types";

const initialState = {
  demo: {},
  loading: false,
};

const demo = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEMO_SUCCESS:
      return {
        ...state,
        demo: action.payload,
        loading: false,
      };
    case GET_DEMO_BY_SLUG:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case GET_DEMO_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default demo;
