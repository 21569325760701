import { useSelector } from 'react-redux';

const Menu = (props) => {
  const { showResources, showFAQs, navigate } = props;
  const { demo } = useSelector((state) => state.demo);
  const sideBarClick = (result_id) => {
    console.log('clickedclciked');
    navigate(`/result/${result_id}`);
  };

  return (
    <div className="menu-options d-flex align-items-center flex-column">
      {demo.sidebar_items
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((item, key) => {
          const menuItem = (
            <Item
              key={key}
              item={item}
              onClick={() => {
                if (item.is_faq) {
                  showFAQs();
                } else if (item.is_resource) {
                  showResources();
                } else {
                  sideBarClick(item.result.id);
                }
              }}
            />
          );
          return menuItem;
        })}
    </div>
  );
};

const Item = (props) => {
  const { item, onClick } = props;
  return (
    <div className="menu-btn d-flex">
      <img className="menu-icon" src={item.icon} alt="icon" onClick={onClick} />
    </div>
  );
};

export default Menu;
