/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import HotSpot from './Hotspot';
import { connect } from 'react-redux';
import { BackBtn } from './Buttons';
import SlideOut from './SlideOut';
import ResourceList from './ResourceList';
import { FaqList } from './FAQList';
import Menu from './Menu';

const imgSize = [2500, 1406];

class Scene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      showResources: false,
      showFAQs: false,
      showContactUs: false,
      showThankYou: false,
    };
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    this.orientationResize();
    window.addEventListener('resize', this.orientationResize.bind(this));
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.orientationResize.bind(this));
  }

  calcHeight(newWidth) {
    return newWidth * (imgSize[1] / imgSize[0]);
  }

  calcWidth(newHeight) {
    return newHeight * (imgSize[0] / imgSize[1]);
  }

  orientationResize() {
    var dynVidContSize = [window.innerWidth, window.innerHeight];

    var adjHeight = this.calcHeight(dynVidContSize[0]);
    var adjWidth = this.calcWidth(dynVidContSize[1]);

    // If the image is not going to fit height we need to make sure the height doesnt overflow and scale the width
    // adjusted width = <user-chosen height> * original width / original height
    if (adjHeight > window.innerHeight) {
      var newWidth = this.calcWidth(window.innerHeight);
      this.setState((state, props) => {
        return {
          width: newWidth,
          height: window.innerHeight,
        };
      });
    }
    // If the image is going to be to wide, we need to make sure the width doesnt overflow and scale the height
    // adjusted height = <user-chosen width> * original height / original width
    else if (adjWidth > window.innerWidth) {
      var newHeight = this.calcHeight(window.innerWidth);
      this.setState((state, props) => {
        return {
          width: window.innerWidth,
          height: newHeight,
        };
      });
    } else {
      this.setState((state, props) => {
        return {
          width: adjWidth,
          height: adjHeight,
        };
      });
    }
  }

  showResources = () => {
    this.setState({
      showResources: true,
      showFAQs: false,
    });
  };

  hideResources = () => {
    this.setState({ showResources: false });
  };

  showFAQs = () => {
    this.setState({
      showFAQs: true,
      showResources: false,
    });
  };

  showContactUs = () => {
    this.setState({
      showFAQs: false,
      showContactUs: true,
    });
  };

  hideContactUs = () => {
    this.setState({
      showFAQs: true,
      showContactUs: false,
    });
  };

  hideThankYou = () => {
    this.setState({
      showFAQs: true,
      showContactUs: false,
      showThankYou: false,
    });
  };

  contactUsSubmit = () => {
    // this is the form data to send
    this.setState({
      showThankYou: true,
      showContactUs: false,
    });
  };

  hideFAQs = () => {
    this.setState({ showFAQs: false });
  };

  showSpecs = () => {
    const { navigate, demo } = this.props;
    navigate(`/result/${demo.spec_video.id}`);
  };

  render() {
    var hotspots = [];

    this.props.results.map((data, index) => {
      if (!data.is_child) {
        hotspots.push(
          <HotSpot
            nextPage={data.id}
            type="result"
            hotspot_x_location={data.hotspot_x_location}
            hotspot_y_location={data.hotspot_y_location}
            tooltip={data.tooltip}
            key={index}
            scene_width={this.state.width}
            scene_height={this.state.height}
            children={data.children}
            name={data.name}
            navigate={this.props.navigate}
            transition={data.transition}
          />
        );
      }
    });

    const style = {
      backgroundImage: `url(${this.props.backgroundImg})`,
      width: this.state.width,
      height: this.state.height,
      backgroundSize: 'cover',
      margin: 'auto',
    };

    let backBtn = this.props.scene.is_landing ? null : (
      <BackBtn id={this.props.match.params.prev_id} />
    );

    const fadeInOverlay =
      this.state.showResources ||
      this.state.showFAQs ||
      this.state.showContactUs ||
      this.state.showThankYou;

    return (
      <div className="vidContCell d-flex align-self-center">
        <div
          className={`gray-out-overlay ${fadeInOverlay ? 'fade-in' : ''}`}
        ></div>
        <div className="scene d-flex align-self-center" style={style}>
          {backBtn}
          {hotspots}
        </div>
        <React.Fragment>
          <Menu
            showResources={this.showResources}
            showFAQs={this.showFAQs}
            showSpecs={this.showSpecs}
            navigate={this.props.navigate}
          />
          <SlideOut
            visible={this.state.showResources}
            title="Resources"
            onDismiss={this.hideResources}
          >
            <ResourceList />
          </SlideOut>
          <SlideOut
            visible={this.state.showFAQs}
            title="FAQs"
            onDismiss={this.hideFAQs}
          >
            <FaqList contactUsClick={() => this.showContactUs()} />
          </SlideOut>
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  results: state.result.results,
  demo: state.demo.demo,
});

export default connect(mapStateToProps)(Scene);
