import { useSelector } from 'react-redux';

const ResourceList = () => {
  const {
    demo: { resources },
  } = useSelector((state) => state.demo);

  return (
    <div>
      {resources &&
        resources.map((resource) => (
          <div className="d-flex p-2" key={resource.id}>
            <a
              className="resource-link"
              href={resource.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {resource.text}
            </a>
          </div>
        ))}
    </div>
  );
};

export default ResourceList;
